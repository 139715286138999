<template>
  <TemplateBackground>
    <template v-slot:label>
      <component :is="'p'" class="flex justify-center flex-col max-w-[960px] mx-auto">
        <h5 class="inline">
          Bem-vindo! Você está na página de <b class="uppercase">pesquisa de fornecedores de produtos e serviços.</b> Aqui você pode localizar os fornecedores que deseja. Informe abaixo os dados de Login para visualizar os fornecedores. Caso não tenha cadastro é só clicar em Clique Aqui para se Cadastrar e faça seu cadastro no sistema.
        </h5>
      </component>
    </template>

    <template v-slot:content>
      <form v-on:submit.prevent="onSend">
        <div class="mt-2">
          <Input
            name="email"
            type="email"
            placeholder="Digite seu e-mail"
            validate-with-on-blur
            :value="formData.email"
            :on-change="(value) => formData.email = value"
            :on-validate="(validate) => formDataValidate.email = validate"
            :validate="value => useValidations('email', value)"
            :helper-text="'e-mail inválido'"
            :helper-text-class="'text-white bg-orange-400 p-1 rounded-md'"
            :validate-on-update="isValideAll"
            autocomplete="off"
          />
        </div>

        <div class="mt-6">
          <Input
            name="password"
            type="password"
            placeholder="Informe sua senha"
            :value="formData.passwd"
            validate-with-on-blur
            :on-change="(value) => formData.passwd = value"
            :on-validate="(validate) => formDataValidate.passwd = validate"
            :validate="(value) => value?.length >= 4"
            :helper-text="'senha deve ser maior que 4 caracteres'"
            :helper-text-class="'text-white font-semibold bg-orange-400 p-1 rounded-md'"
            :validate-on-update="isValideAll"
            autocomplete="off"
          />
        </div>

        <Message v-if="statusResponse >= 400" class="mt-10" type="warn">
          <p v-if="statusResponse === 403">Conta pendente de confirmação.</p>
          <p v-else-if="statusResponse >= 500">{{ statusResponse }} - Erro inesperado.</p>
          <p v-else>Credenciais inválidas.</p>
        </Message>

        <Message v-else-if="!statusResponse" class="mt-10" type="warn">
          Verifique sua conexão e tente novamente.
        </Message>

        <Button
          :is-loading="isLoading"
          loading-size="md"
          is-width-full
          type="submit"
          class="bg-secondary-500 mt-5"
        >
          Entrar
        </Button>

      </form>

      <div class="flex flex-row">
        <div class="basis-1/4">
          <p class="mt-3 text-center text-sm text-gray-500">
            <NuxtLink href="/" class="text-white border-white border-2 rounded-md py-2 pr-4 pl-4 text-[15px]">
              Voltar
            </NuxtLink>
          </p>
        </div>
        <div class="basis-3/4">
          <p class="mt-3 text-right text-sm text-gray-500">
            <NuxtLink href="/recovery" class="text-white text-[15px]">
              Esqueci minha senha
            </NuxtLink>
          </p>
        </div>
      </div>

      <p class="mt-3 text-center text-sm text-gray-500">
        <NuxtLink href="/register" class="text-white text-[17px]">
          Não possui cadastro? <b>Cadastre-se</b>
        </NuxtLink>
      </p>
    </template>
  </TemplateBackground>
</template>

<script lang="ts" setup>
definePageMeta({ middleware: ["auth"] });

const router = useRouter();

const { auth } = useUser();
const { setUser, setToken, setHasCompany, setRegisterService } = useUserStore();

const statusResponse = ref(-1);
const isLoading = ref(false);

const isValideAll = ref(false);

const formData = reactive({
  email: "",
  passwd: ""
});

const formDataValidate = reactive({
  email: false,
  passwd: false
});

function onSend(e: Event){
  e.preventDefault();

  isValideAll.value = true;

  if (!validateFormData(formDataValidate)) return;

  isLoading.value = true;

  auth(formData)
    .then(({ data: { token, user, hasCompany, isRegisterService } }) => {
      setUser(user);
      setToken(token);
      setRegisterService(!!(isRegisterService));

      if(hasCompany){
        setHasCompany(hasCompany);
        router.replace("/home");
      }else if(user.type === 3){ // usuário comum
        router.replace("/home");
      }else{
        router.replace("/register/steps");
      }
    })
    .catch((err) => {
      statusResponse.value = err?.response?.status ?? 0;
    }).finally(() => {
      isLoading.value = false;
    });
}

useHead({ title: "Login" });

</script>